import React, { Fragment } from "react";
import { domain } from "../../../utils";
import googlePlay from "../../../images/google-play-store.svg";
import appStore from "../../../images/app-store.svg";
import logo from "../../../images/logo.svg";


export class Banner extends React.Component {
  constructor(props) {
    super(props);

    this.getRandomImg = this.getRandomImg.bind(this);
  }
  
  getRandomImg(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }
    
  render () {
  const { media, title, description } = this.props.banner;
  const wrappedTitle = title.split(' ');
    return (
      <Fragment>
        <div className="header section-header" id="header">
          <div className="header section-header-content">
            <div className="container-fluid mb-3 mb-md-5">
              <div className="row py-md-4">
                <div className="col-12 col-md-10 offset-md-1 p-0">
                  <div className="container-fluid">
                    <div className="row align-items-center">
                      <div className="col-12 col-md-6 pt-5 pt-md-0 order-md-2 pl-xl-5 pr-0 p-0">
                        <img className="w-100 bg-flower" alt="coopz logo" src={logo} width="auto"/>
                        <img
                          className="teaser-img"
                          alt="teaser"
                          src={`${domain}${media[this.getRandomImg(media.length)].url}`}
                        />
                      </div>
                      <div className="col-12 col-md-6 pb-3 order-md-1 mt-md-5">
                        <h1 className="text-uppercase d-xxl-none">{title}</h1>
  
                        {/* Title with linebreak */}
                        <h1 className="text-uppercase d-xxl-block ml-0">{wrappedTitle[0]} <br/> {wrappedTitle.shift() && wrappedTitle.join(' ')}</h1>
  
                        <p className=" m-0 mb-3 mb-md-0">{description}</p>
                        {/* <p className="ml-xl-4 d-xxl-block">{description}</p> */}
                        <div className="row justify-content-sm-center justify-content-md-start mt-lg-1 mt-xl-3 text-center">
                          <div className="col-6 app-store-buttons">
                            <a
                              className="p-0 my-btn-store w-100"
                              href="https://play.google.com/store/apps/details?id=com.coopzapp"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img className="w-100" alt="Google Play" src={googlePlay} width="auto" />
                            </a>
                          </div>
                          <div className="col-6 app-store-buttons">
                            <a
                              className="p-0 my-btn-store w-100"
                              href="https://apps.apple.com/app/id1467176993"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img className="w-100" alt="App Store" src={appStore} width="auto" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}
